import React from 'react';

import { useSettings } from '@wix/tpa-settings/react';
import { useMemberPaidPlans } from '../../../../contexts/PaidPlans/paidPlansContext';
import { useSettingsEvents } from '../../../../contexts/SettingsEvents/SettingsEvents';
import {
  Pages,
  useLocation,
} from '../../../../contexts/Location/LocationContext';
import { useEnvironment, useBi } from '@wix/yoshi-flow-editor';
import { memberWebAppButtonClick as memberWebAppButtonClickV2 } from '@wix/bi-logger-challenges-member-web/v2';

import settingsParams from '../../settingsParams';
import { ButtonNames } from '../../../../contexts/main/biInterfaces';
import { ILayoutType } from '../../Settings/challengeSettings/challengeSettings.types';

import { FedopsInteraction } from '../../../../config/fedopsInteraction';
import { useMonitoring } from '../../../../contexts/Monitoring/MonitoringContext';

import { getIsFullWidth } from '../../../../selectors/platform';
import {
  Challenge,
  MemberChallenge,
} from '@wix/ambassador-challenges-v1-challenge/types';

import { ChallengeCard } from '../ChallengeCard';

import { classes, st } from './ChallengesList.st.css';

const getGridStyles = (isMobile: boolean, settings: any) => {
  const layoutType = settings.get(settingsParams.layoutType);

  return layoutType === ILayoutType.Grid
    ? {
        gridTemplateColumns: `repeat(auto-fit, minmax(max(240px, calc(100% / ${
          (isMobile
            ? 1
            : (settings.get(settingsParams.itemsPerRow) as number)) + 1
        })), 1fr))`,
        gridGap: `${settings.get(settingsParams.cardSpacing)}px`,
        justifyItems:
          settings.get(settingsParams.itemsPerRow) === 1 || isMobile
            ? `center`
            : null,
      }
    : {
        gridTemplateColumns: `1fr`,
        gridGap: `${settings.get(settingsParams.sideCardsVerticalSpacing)}px`,
        justifyItems: `center`,
      };
};

export const ChallengesList: React.FC<{
  challenges: MemberChallenge[];
  isEditorLoaded: boolean;
  changeIsLoading(): void;
}> = ({ challenges, isEditorLoaded, changeIsLoading }) => {
  const settings = useSettings();
  const { isMobile, dimensions } = useEnvironment();
  const { goToPage } = useLocation();
  const { userPaidPlans } = useMemberPaidPlans();
  const { buttonState } = useSettingsEvents();
  const bi = useBi();
  const { startInteraction } = useMonitoring();

  const beforeGoToProgramPage = React.useCallback((challenge: Challenge) => {
    if (bi.report) {
      bi.report(
        memberWebAppButtonClickV2({
          buttonName: ButtonNames.ViewChallenge,
          challengeId: challenge?.id,
        }),
      );
    }

    startInteraction(FedopsInteraction.ViewProgramFromCard, challenge?.id);

    changeIsLoading();
  }, []);

  const goToProgramPage = React.useCallback((challenge: Challenge) => {
    goToPage({
      pageId: Pages.Details,
      challengeId: challenge?.settings?.seo?.slug,
      reloadPage: true,
    });
  }, []);

  return (
    <ul
      data-hook="challenge-list"
      className={st(classes.root, { mobile: isMobile })}
      style={getGridStyles(isMobile, settings)}
    >
      {challenges.map((ch, ind) => {
        const challenge: Challenge = ch?.challenge;

        return (
          <li className={classes.item} key={ind}>
            {challenge ? (
              <ChallengeCard
                memberChallenge={ch}
                isFullWidth={getIsFullWidth(dimensions)}
                goToPage={() => {
                  beforeGoToProgramPage(challenge);
                  goToProgramPage(challenge);
                }}
                userPaidPlans={userPaidPlans}
                buttonState={buttonState}
                even={ind % 2 === 0}
              />
            ) : null}
          </li>
        );
      })}
    </ul>
  );
};
